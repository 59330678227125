import React, { useState, Fragment } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Video from "@components/Video";
import SEO from "@components/SEO";
import Layout from "@components/Layout";

import { materialVideos } from "@utils/static-data";

import styles from "@cssmodules/materialdesc.module.scss";

export default function Measurement() {
    const [activeTabs, setActiveTabs] = useState({ index: 0 });

    const renderMaterialDescList = () => {
        return materialVideos.map((data, idx) => (
            <div
                key={idx}
                className={`d-flex mb-1 ${styles.list} ${
                    idx === activeTabs.index ? styles.active : ``
                }`}
                onClick={() => setActiveTabs({ index: idx })}
            >
                <span>{idx + 1}.&nbsp;</span>
                <span>{data.title.replace("Penjelasan", "")}</span>
            </div>
        ));
    };

    const renderMeasurementContent = () => {
        let title = materialVideos[activeTabs.index].title;
        let video_link = materialVideos[activeTabs.index].link;
        return (
            <Fragment>
                <h4
                    style={{
                        background: "#eee",
                        padding: "0.5rem",
                        borderRadius: "1rem",
                        textAlign: "center",
                    }}
                >
                    {activeTabs.index + 1}. {title.replace("Penjelasan", "")}
                </h4>
                <div className={styles.video}>
                    <Video title={title} link={video_link} quality="sd" />
                </div>
            </Fragment>
        );
    };

    return (
        <Layout>
            <main id="main-content" className={styles.main}>
                <SEO title="Standar Ukuran" />
                <Container>
                    <h2 className={`${styles.title} d-none d-md-block`}>
                        Bingung nentuin bahan untuk seragam yang akan dipesan?
                        jangan khawatir,
                        <br />
                        berikut video penjelasan tentang masing-masing bahan
                        yang paling
                        <br />
                        sering digunakan di BIKINSERAGAM.ID
                    </h2>
                    <h2 className={`${styles.title} d-block d-md-none`}>
                        Bingung nentuin bahan untuk seragam yang akan dipesan?
                        jangan khawatir, berikut video penjelasan tentang
                        masing-masing bahan yang paling sering digunakan di
                        BIKINSERAGAM.ID
                    </h2>
                    <Row className="flex-column-reverse flex-md-row-reverse">
                        <Col xs={12} md={6} className="mb-3">
                            <Card
                                className="border-0"
                                style={{
                                    backgroundColor: "#eee",
                                    borderRadius: "1.5rem",
                                    height: "21rem",
                                }}
                            >
                                <Card.Body>
                                    <div className={styles.listContainer}>
                                        {renderMaterialDescList()}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={6} className="mb-3">
                            {renderMeasurementContent()}
                        </Col>
                    </Row>
                </Container>
            </main>
        </Layout>
    );
}
